.mainTitle{
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 28px;
    margin-left: 0.5em;
}

.secondaryTitle{
    font-weight: 550;
    font-size: 20px;
    /*text-decoration: none; No toma esta propiedad desde la hoja de estilos sólo lo toma declarando esta regla inline - en el componente*/
}

.optionBox{
    border-bottom: 1px solid #F4F3F3;
    padding:13px;
}

.optionBox:hover{
    background-color: #eeeeee;
}